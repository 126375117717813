import Dexie from "dexie";

export const db = new Dexie("appOSEVO");

export async function dbClear() {
  await db.os_feed?.clear();
  await db.os_open?.clear();
  await db.os_closed?.clear();
  await db.os_products?.clear();
}

db.version(10).stores({
  images: "name, file",
  user: "id,token, name",
  os_feed:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at, opened_at, resolution, media, manual, assinatura, closed_at, props, checked, check_in_box",
  os_open:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at, opened_at, resolution, media, manual, assinatura, props, closed_at, client_name, step,client_cargo,check_in_date, location,check_in_box",
  os_closed:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at, opened_at, resolution, media, manual, assinatura, closed_at, props, check_in_box",
  products: "id, nome, valor, peso, os"
});
