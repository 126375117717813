import React, { useRef } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const Container = styled.div`
  z-index: 99;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 60px;
  bottom: 60px;
  flex: 1;
`;
const SignatureText = styled.span`
  position: absolute;
  top: 25%;
  right: 0;
  width: 50px;
  height: 50%;
  border-right: 1px solid black;
  text-align: center;
  writing-mode: tb-rl;
  transform: rotate(180deg);
`;

const BtnContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  right: 0;
`;

export default function SignatureCanvas({ onSave, onCancel }) {
  const sigCanvas = useRef({});
  function save() {
    const data = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    if (onSave) onSave(data);
  }
  function cancel() {
    sigCanvas.current.clear();
    if (onCancel) onCancel();
  }

  return (
    <>
      <Container>
        <ReactSignatureCanvas
          ref={sigCanvas}
          clearOnResize={false}
          penColor="black"
          backgroundColor="#FFF"
          canvasProps={{
            className: "sigCanvas",
            style: {
              width: "100%",
              height: "100%",
              border: "1px solid black",
              borderRadius: 5,
            },
          }}
        />
        <SignatureText>Assinatura</SignatureText>
      </Container>
      <BtnContainer>
        <Button variant="contained" onClick={save}>
          Salvar
        </Button>
        <Button variant="contained" onClick={() => sigCanvas.current.clear()}>
          Limpar
        </Button>
        <Button variant="contained" onClick={cancel}>
          Cancelar
        </Button>
      </BtnContainer>
    </>
  );
}
